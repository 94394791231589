<template>
  <div class="mt--8 pb-5 container vh-100">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6">
        <div class="card bg-secondary border-0">
          <Form @submit="handleLogin" :validation-schema="schema" class="p-5">
            <div v-if="!successful" class="card-body px-lg-5 py-lg-5">
              <div class="text-center mb-4">
                <h1>Sign in</h1>
              </div>
              <span>
                <div class="form-group">
                  <label for="email">Email</label>
                  <Field name="email" type="email" class="form-control" />
                  <ErrorMessage
                    name="email"
                    class="error-feedback text-danger" />
                </div>
                <div class="form-group">
                  <label for="password">Password</label>
                  <Field name="password" type="password" class="form-control" />
                  <ErrorMessage
                    name="password"
                    class="error-feedback text-danger" />
                </div>
                <div class="d-flex justify-content-between mt-2">
                  <div class="form-group">
                    <button
                      class="btn btn-primary btn-block"
                      :disabled="loading">
                      <span
                        v-show="loading"
                        class="spinner-border spinner-border-sm"></span>
                      <span>Login</span>
                    </button>
                  </div>
                  <b-link href="/passwordReset"> Password Reset </b-link>
                </div>
                <div class="form-group">
                  <div v-if="message" class="alert alert-danger" role="alert">
                    {{ message }}
                  </div>
                </div>
              </span>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      email: yup
        .string()
        .required("Email is required!")
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),
      password: yup.string().required("Password is required!"),
    });
    return {
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push({ name: "statistics" });
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;
      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.push("/statistics");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  }
};
</script>
<style scoped>
.profile-img-card {
  width: 25%;
  height: 25%;
}
.bg-secondary {
  background-color: #f7fafc !important;
}
</style>
