import axios from "axios";
import { config } from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;
axios.defaults.headers.put['Content-Type'] = 'application/json';

class SettingService {
  getSetting(url) {
    return axios.get(API_URL + url, {
      headers: authHeader(),
    });
  }
  updateSetting(url, setting) {
    return axios.put(API_URL + url, setting, {
      headers: authHeader(),
    });
  }
}
export default new SettingService();
