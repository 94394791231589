<template>
  <div>
    <b-navbar toggleable="lg" class="bg-gradient-success" type="light">
      <b-navbar-brand href="#">{{ currentLogo }}</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item 
            href="/statistics" 
            :active="$route.name === 'statistics'" 
            v-if="currentUser"
            >IP Statistics</b-nav-item
          >
          <b-nav-item 
            href="/ip-announcements" 
            :active="$route.name === 'ip-announcements'" 
            v-if="currentUser"
            >IP Announcements History</b-nav-item
          >
          <b-nav-item
            href="/allowed-prefixes"
            v-if="currentUser"
            :active="$route.name === 'allowed-prefixes'"
            >Manual IP Announcements</b-nav-item
          >
          <b-nav-item
            href="/snmp-devices"
            v-if="currentUser"
            :active="$route.name === 'snmp-devices'"
            >SNMP Polling</b-nav-item
          > 
          <b-nav-item
            href="/admin"
            v-if="showAdminBoard"
            :active="$route.name === 'admin'"
            >Users</b-nav-item
          >
          <b-nav-item
            href="/mod"
            v-if="showModeratorBoard"
            :active="$route.name === 'moderator'"
            >Moderator Board</b-nav-item
          >
          <!-- <b-nav-item href="/user" v-if="currentUser">user</b-nav-item> -->
          <b-nav-item
            href="/flowspec"
            v-if="currentUser"
            :active="$route.name === 'rule'"
            >Manual Rules</b-nav-item
          >
          <b-nav-item
            href="/active-rules"
            v-if="currentUser"
            :active="$route.name === 'active-rules'"
            >Active Rules</b-nav-item
          > 
          <b-nav-item
            href="/static-actions"
            v-if="currentUser"
            :active="$route.name === 'static-actions'"
            >Static Actions</b-nav-item
          > 
           <b-nav-item
              href="/threshold"
              v-if="currentUser"
              :active="$route.name === 'threshold'"
              >Thresholds</b-nav-item
            > 
          <b-nav-item
              href="/setting/edit"
              v-if="showAdminBoard"
              :active="$route.name === 'setting-edit'"
              >Settings</b-nav-item
            >
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ms-auto">
          <b-nav-item href="/register" v-if="!currentUser">Sign up</b-nav-item>
          <b-nav-item href="/login" v-if="!currentUser">Login</b-nav-item>
          <b-nav-item-dropdown right v-if="currentUser">
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em> {{ currentUser.username }}</em>
            </template>
            <b-dropdown-item href="/profile">Profile</b-dropdown-item>
            <b-dropdown-item href="#" @click.prevent="logOut"
              >Sign Out</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script>
import SettingService from "../services/setting.service";

export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    currentLogo() {
      return this.$store.state.setting.tag;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.role) {
        return this.currentUser.role === "admin";
      }
      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.role) {
        return this.currentUser.role === "moderator";
      }
      return false;
    },
  },
  mounted() {
    this.getSetting();
    this.setTitle(localStorage.getItem('page_title'));
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    async getSetting() {
      const url = "settings";
      const response = await SettingService.getSetting(url);
      if (response.status == 200 && response.data.websiteName) {
        this.setting = response.data;
        this.$store.dispatch("setting/setLogo", this.setting.websiteName);
        this.setTitle(this.setting.websiteName);
      }
    },
  },
};
</script>
<style scoped>
.bg-gradient-success {
  background: linear-gradient(87deg, #2dce89, #2dcecc) !important;
}

.navbar-nav {
  --bs-navbar-active-color: white !important;
}
.navbar-nav li:not(:last-child) {
  border-right: 1px solid white !important;
}
</style>
