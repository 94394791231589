const initialState = { tag: localStorage.getItem('page_title') || '' };

export const setting = {
  namespaced: true,
  state: initialState,
  mutations: {
    setLogoSuccess(state, tag) {
      state.tag = tag;
      localStorage.setItem("page_title", tag);
    },
    setIntervalSuccess(state, interval) {
      localStorage.setItem("bgp_interval", interval);
    },
  },
  actions: {
    setLogo({ commit }, tag) {
      commit("setLogoSuccess", tag);
    },
    setInterval({ commit }, interval) {
      commit("setIntervalSuccess", interval);
    },
  },
};
