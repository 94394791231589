import { createStore } from "vuex";
import { auth } from "./auth.module";
import { setting } from "./setting.module";

const store = createStore({
  modules: {
    auth,
    setting,
  },
});
export default store;
