import { createWebHistory, createRouter } from "vue-router";
import Login from "./views/auth/Login.vue";
import Register from "./views/auth/Register.vue";
// lazy-loaded
const Profile = () => import("./views/auth/Profile.vue");
const BoardAdmin = () => import("./views/user/BoardAdmin.vue");
const BoardModerator = () => import("./views/common/BoardModerator.vue");
const BoardUser = () => import("./views/common/BoardUser.vue");
const BoardUserEdit = () => import("./views/user/PageUserEdit.vue");
const AdminLayout = () => import("./layout/PageLayout.vue");
const ActiveRuleLayout = () => import("./layout/ActiveRuleLayout.vue");
const ThresholdLayout = () => import("./layout/ThresholdLayout.vue");
const StaticActionLayout = () => import("./layout/StaticActionLayout.vue");
const FlowSpec = () => import("./views/rule/FlowSpec.vue");
const FlowSpecLayout = () => import("./layout/FlowSpecLayout.vue");
const FlowSpecCreate = () => import("./views/rule/FlowSpecAdd.vue");
const FlowSpecEdit = () => import("./views/rule/FlowSpecEdit.vue");
const Welcome = () => import("./views/common/Welcome.vue");
const PasswordReset = () => import("./views/auth/PasswordReset.vue");
const PasswordResetInput = () => import("./views/auth/PasswordResetInput.vue");
const SettingEditPage = () => import("./views/setting/Edit.vue");
const ActiveRulesPage = () => import("./views/active-rule/ActiveRule.vue");
const ActiveRuleEdit = () => import("./views/active-rule/ActiveRuleEdit.vue");
const StaticActionsPage = () => import("./views/static-action/StaticActions.vue");
const StaticActionAddEdit = () => import("./views/static-action/StaticActionAddEdit.vue");
const ThresholdPage = () => import("./views/statistics/Threshold.vue");
const SettingLayout = () => import("./layout/SettingLayout.vue");
const IPStatisticsPage = () => import("./views/statistics/list.vue");
const SnmpDevicesLayout = () => import("./layout/SnmpDevicesLayout.vue");
const SnmpPage = () => import("./views/snmp-devices/SnmpDevices.vue");
const SnmpAddEdit = () => import("./views/snmp-devices/SnmpAddEdit.vue");
const IpAnnouncements = () => import("./views/allowed-prefixes/IpAnnouncements.vue");
const AllowedPrefixesLayout = () => import("./layout/AllowedPrefixesLayout.vue");
const AllowedPrefixes = () => import("./views/allowed-prefixes/AllowedPrefixes.vue");
const AllowedPrefixesAddEdit = () => import("./views/allowed-prefixes/AllowedPrefixesAddEdit.vue");
const routes = [
  {
    path: "/statistics",
    name: "statistics",
    component: IPStatisticsPage,
  },
  {
    path: "/",
    name: "login",
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
  },
  {
    path: "/passwordReset",
    name: "passwordReset",
    component: PasswordReset,
  },
  {
    path: "/passwordResetInput/:confirmationCode",
    name: "passwordResetInput",
    component: PasswordResetInput,
  },
  {
    path: "/confirm/:confirmationCode",
    name: "welcome",
    component: Welcome,
  },
  {
    path: "/admin",
    component: AdminLayout,
    children: [
      {
        path: "",
        name: "admin",
        component: BoardAdmin,
      },
      {
        path: "user/edit/:id",
        name: "BoardUserEdit",
        component: BoardUserEdit,
      },
    ],
  },
  {
    path: "/mod",
    name: "moderator",
    // lazy-loaded
    component: BoardModerator,
  },
  {
    path: "/setting",
    component: SettingLayout,
    children: [
      {
        path: "edit",
        name: "setting-edit",
        component: SettingEditPage,
      },
    ],
  },
  {
    path: "/active-rules",
    component: ActiveRuleLayout,
    children: [
      {
        path: "",
        name: "active-rules",
        component: ActiveRulesPage,
      },
      {
        path: "edit/:id",
        name: "ActiveRuleEdit",
        component: ActiveRuleEdit,
      },
    ],
  },
  {
    path: "/static-actions",
    component: StaticActionLayout,
    children: [
      {
        path: "",
        name: "static-actions",
        component: StaticActionsPage,
      },
      {
        path: "edit/:id",
        name: "staticActionAdd",
        component: StaticActionAddEdit,
      },
      {
        path: "add/",
        name: "staticActionEdit",
        component: StaticActionAddEdit,
      },
    ],
  },
  {
    path: "/threshold",
    component: ThresholdLayout,
    children: [
      {
        path: "",
        name: "threshold",
        component: ThresholdPage,
      },
    ],
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: BoardUser,
  },
  {
    path: "/flowspec",
    component: FlowSpecLayout,
    children: [
      {
        path: "",
        name: "rule",
        component: FlowSpec,
      },
      {
        path: "create",
        name: "flowspecCreate",
        component: FlowSpecCreate,
      },
      {
        path: "edit/:id",
        name: "flowspecEdit",
        component: FlowSpecEdit,
      },
    ],
  },
  {
    path: "/snmp-devices",
    component: SnmpDevicesLayout,
    children: [
      {
        path: "",
        name: "snmp-devices",
        component: SnmpPage,
      },
      {
        path: "edit/:id",
        name: "snmpEdit",
        component: SnmpAddEdit,
      },
      {
        path: "add/",
        name: "snmpAdd",
        component: SnmpAddEdit,
      },
    ],
  },
  {
    path: "/ip-announcements",
    component: IpAnnouncements,
    name: "ip-announcements"
  },
  {
    path: "/allowed-prefixes",
    component: AllowedPrefixesLayout,
    children: [
      {
        path: "",
        name: "allowed-prefixes",
        component: AllowedPrefixes,
      },
      {
        path: "edit/:id",
        name: "allowedPrefixesEdit",
        component: AllowedPrefixesAddEdit,
      },
      {
        path: "add/",
        name: "allowedPrefixesAdd",
        component: AllowedPrefixesAddEdit,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  const publicPages = [
    "/",
    "/register",
    "/home",
    "/passwordReset",
    "/confirm",
    "/welcome",
    "/passwordResetInput",
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/");
  } else {
    next();
  }
});
export default router;
