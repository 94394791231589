<template>
  <div class="mt--8 pb-5 container vh-100">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6">
        <div class="card bg-secondary border-0">
          <Form
            @submit="handleRegister"
            :validation-schema="schema"
            class="p-5"
          >
            <div v-if="!successful" class="card-body px-lg-5 py-lg-5">
              <div class="text-center mb-4">
                <h1>Sign up</h1>
              </div>
              <span>
                <div class="form-group">
                  <label for="username">Username</label>
                  <Field name="username" type="text" class="form-control" />
                  <ErrorMessage
                    name="username"
                    class="error-feedback text-danger"
                  />
                </div>
                <div class="form-group">
                  <label for="email">Email</label>
                  <Field name="email" type="email" class="form-control" />
                  <ErrorMessage
                    name="email"
                    class="error-feedback text-danger"
                  />
                </div>
                <div class="form-group">
                  <label for="password">Password</label>
                  <Field name="password" type="password" class="form-control" />
                  <ErrorMessage
                    name="password"
                    class="error-feedback text-danger"
                  />
                </div>
                <div class="form-group">
                  <label for="password">Password Confirmation</label>
                  <Field
                    name="confirm_password"
                    type="password"
                    class="form-control"
                  />
                  <ErrorMessage
                    name="confirm_password"
                    class="error-feedback text-danger"
                  />
                </div>
                <div class="form-group mt-2">
                  <button class="btn btn-primary btn-block" :disabled="loading">
                    <span
                      v-show="loading"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    Sign Up
                  </button>
                </div>
              </span>
            </div>
          </Form>
          <div
            v-if="message"
            class="alert"
            :class="successful ? 'alert-success' : 'alert-danger'"
          >
            {{ message }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
export default {
  name: "Register",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup
        .string()
        .required("Username is required!")
        .min(3, "Must be at least 3 characters!")
        .max(20, "Must be maximum 20 characters!"),
      email: yup
        .string()
        .required("Email is required!")
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),
      password: yup
        .string()
        .required("Password is required!")
        .min(6, "Must be at least 6 characters!")
        .max(40, "Must be maximum 40 characters!"),
      confirm_password: yup
        .string()
        .label("confirm password")
        .required()
        .oneOf([yup.ref("password"), null], "Passwords must match"),
    });
    return {
      successful: false,
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push({ name: "profile" });
    }
  },
  methods: {
    handleRegister(user) {
      this.message = "";
      this.successful = false;
      this.loading = true;
      this.$store.dispatch("auth/register", user).then(
        (data) => {
          this.message = data.message;
          this.successful = true;
          this.loading = false;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
          this.loading = false;
        }
      );
    },
  },
};
</script>
<style scoped>
.profile-img-card {
  width: 25%;
  height: 25%;
}
.bg-secondary {
  background-color: #f7fafc !important;
}
</style>
