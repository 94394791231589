<template>
  <div>
    <Navbar />
    <div class="bg-default main-page">
      <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
        <div class="separator separator-bottom separator-skew zindex-100">
          <svg
            x="0"
            y="0"
            viewBox="0 0 2560 100"
            preserveAspectRatio="none"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon
              points="2560 0 2560 100 0 100"
              class="fill-default"
            ></polygon>
          </svg>
        </div>
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
import Navbar from "@/layout/Navbar.vue";
export default {
  name: "App",
  components: {
    Navbar,
  },
};
</script>
<style scoped>
.bg-gradient-success {
  background: linear-gradient(87deg, #2dce89, #2dcecc) !important;
}
.header {
  position: relative;
}
.bg-default {
  background-color: #172b4d !important;
}

.bg-secondary {
  background-color: #f7fafc !important;
}
.fill-default {
  fill: #172b4d;
}

.main-page {
  min-height: calc(100vh - 70px);
}
</style>
